import api from '../../utils/api'

// 授权
export async function main_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         patient_name: '李三阳',
  //         aget_label: 47,
  //         avatar_url: './img/family-imgs/user_head.png',
  //         members: [
  //           {
  //             name: '李3洋',
  //             relationship: 3,
  //             relationship_label: '本人',
  //             relationship_id: 1115
  //           },
  //           {
  //             name: '李大洋',
  //             relationship: 4,
  //             relationship_label: '父亲',
  //             relationship_id: 111
  //           },
  //           {
  //             name: '王小洋',
  //             relationship: 2,
  //             relationship_label: '妻子',
  //             relationship_id: 211
  //           },
  //           {
  //             name: '李小洋',
  //             relationship: 1,
  //             relationship_label: '儿子',
  //             relationship_id: 311
  //           },
  //           {
  //             name: '黄小胖',
  //             relationship: 5,
  //             relationship_label: '其他',
  //             relationship_id: 3113
  //           },
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/main`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/main`,payload)
}

export async function get_medical_cards_defaults () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '李明',
  //         qr_code: ''
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/medical_cards/defaults`, )
}


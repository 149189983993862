<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <div class="home-containter">
        <div class="home-containter-header">
          <div class="home-containter-header-top">
            <div class="logo">
              <h3>{{ hospital_name }}</h3>
              <p>常关心、优诊疗、少生病、少住院</p>
            </div>
            <div class="message" @click="onTapMessage">
              <img src="@/assets/home/img-home-ai@3x.png" />
              <div class="message-btn">消息</div>
              <div class="message-badge"></div>
            </div>
          </div>
          <div class="home-containter-header-card">
            <div class="user-info">
              <img :src="get_user_head(type, gender)" />
              <div class="user-info-des">
                <!-- <h3 v-if="data.nickname">{{ data.nickname }}</h3>
            <h3 v-if="!token">请先登录系统</h3> -->
                <h3 v-if="!token" @click="login()">
                  <span>请先登录系统</span>
                </h3>
                <template v-else>
                  <h3 v-if="defaults_data.name">
                    {{ defaults_data.name }}
                    <span @click="jup_family()">
                      <img src="@/assets/home/img-home-change-card@3x.png" />
                      切换就诊卡</span
                    >
                  </h3>
                  <h3 v-if="!defaults_data.name">
                    {{ data.nickname }}
                    <span @click="jup_registered()">
                      <img src="@/assets/home/img-home-change-card@3x.png" />
                      绑定就诊卡</span
                    >
                  </h3>
                  <p v-if="defaults_data.name">
                    就诊卡号: {{ defaults_data.patient_id }}
                  </p>
                </template>
              </div>
            </div>
            <div class="qrcode" v-if="defaults_data.name">
              <img src="@/assets/home/img-home-qrcode@3x.png" />
              就诊卡
            </div>
          </div>
        </div>
        <div class="home-containter-header-mask"></div>
        <div class="home-containter-bodyer">
          <div class="home-containter-banner">
            <div class="items" @click="onTapAppointment">
              <img src="@/assets/home/img-home-banner-girls@3x.png" />
            </div>

            <div class="items" @click="onTapOnline">
              <img src="@/assets/home/img-home-banner-boys@3x.png" />
            </div>
          </div>
          <div class="home-containter-ai-guide" @click="onTapConsultation">
            <img src="@/assets/home/img-home-ai@3x.png" />
            <div class="text">
              <span>不知道挂什么科室？</span>健康助手帮您找科室
            </div>
            <img src="@/assets/home/img-home-arrow@3x.png" class="arrow" />
          </div>
          <div class="home-containter-title">
            <h3>常用服务</h3>
          </div>
          <div class="home-containter-menus">
            <div class="home-containter-menus-items" @click="onTapFee">
              <img src="@/assets/home/7@3x.png" />
              在线缴费
            </div>
            <div
              class="home-containter-menus-items"
              @click="onTapHealthReports"
            >
              <img src="@/assets/home/0@3x.png" />
              报告查询
            </div>
            <div class="home-containter-menus-items" @click="onTapCheck">
              <img src="@/assets/home/1@3x.png" />
              预约检查
            </div>
            <div class="home-containter-menus-items" @click="onTapLabs">
              <img src="@/assets/home/2@3x.png" />
              预约检验
            </div>
            <div class="home-containter-menus-items" @click="onTapMoney">
              <img src="@/assets/home/5@3x.png" />
              门诊押金
            </div>

            <div class="home-containter-menus-items" @click="onTapRecords">
              <img src="@/assets/home/14@2x.png" />
              疫情防控
            </div>
            <div class="home-containter-menus-items" @click="onTapHospital">
              <img src="@/assets/home/3@3x.png" />
              住院服务
            </div>

            <div class="home-containter-menus-items" @click="onTapRecords">
              <img src="@/assets/home/4@3x.png" />
              问诊记录
            </div>

            <div class="home-containter-menus-items" @click="onTapHealthDoc">
              <img src="@/assets/home/13@2x.png" />
              健康档案
            </div>

            <div class="home-containter-menus-items" @click="onTapHealthCheck">
              <img src="@/assets/home/12@2x.png" />
              预约体检
            </div>

            <div class="home-containter-menus-items" @click="onTapGirls">
              <img src="@/assets/home/6@3x.png" />
              孕产专区
            </div>
          </div>
          <div class="home-containter-title">
            <h3>免排队 快速复查</h3>
            <p>免挂号 在线预约 报告智慧解读</p>
          </div>
          <div class="home-ad-banner">
            <div class="home-ad-banner-left">
              <img
                src="@/assets/home/img-home-labs@3x.png"
                @click="onTapLabs"
              />
            </div>
            <div class="home-ad-banner-right">
              <img
                src="@/assets/home/img-home-check@3x.png"
                @click="onTapCheck(1)"
                style="margin-bottom: 10px"
              />
              <img
                src="@/assets/home/img-home-tijian@3x.png"
                @click="onTapTJ"
              />
            </div>
          </div>

          <div class="home-containter-title">
            <h3>便民服务</h3>
          </div>
          <div class="home-containter-menus">
            <div class="home-containter-menus-items" @click="onTapCar">
              <img src="@/assets/home/停车@2x.png" />
              停车缴费
            </div>
            <div class="home-containter-menus-items" @click="onTapSee">
              <img src="@/assets/home/探视@2x.png" />
              陪护探视
            </div>
            <div class="home-containter-menus-items" @click="onTapNav">
              <img src="@/assets/home/院内导航@2x.png" />
              院内导航
            </div>
            <div class="home-containter-menus-items" @click="onTapDou">
              <img src="@/assets/home/豆苗@2x.png" />
              小豆苗
            </div>
          </div>

          <div class="home-containter-alert">
            <div class="home-containter-alert-header">
              <img src="@/assets/home/img-home-ai@3x.png" /> 健康小助手智慧提醒
            </div>
            <div class="home-containter-alert-bodyer">
              <div class="home-containter-alert-bodyer-items">
                <img src="@/assets/home/img-home-alert@3x.png" />
                <div class="infos">
                  <h3>报告提醒</h3>
                  <p>您有3份报告等待查阅</p>
                </div>
                <div class="date">1小时前</div>
              </div>

              <div class="home-containter-alert-bodyer-items">
                <img src="@/assets/home/img-home-alert@3x.png" />
                <div class="infos">
                  <h3>报告提醒</h3>
                  <p>您有3份报告等待查阅</p>
                </div>
                <div class="date">1小时前</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { main_info, get_medical_cards_defaults } from "./service"
import { setSession, getSession, removeSession } from "../../utils/session"
import { get_user_head } from "../../utils/imgUrl"

export default {
  data() {
    return {
      hospital_name: GLOBAL_CONFIG.hospital_name,
      works: [{}],
      data: {},
      defaults_data: {},
      bind_car: getSession("bind_car"),
      gender: null,
      type: null,
    }
  },
  computed: {
    token() {
      return getSession() && getSession().token
    },
    has_card() {
      return getSession() && getSession().has_card
    },
  },
  components: {},
  watch: {
    token() {
      this.main()
      this.medical_cards_defaults()
    },
  },
  created() {
    if (this.token) {
      this.main()
      this.medical_cards_defaults()
    }
  },
  async mounted() {
    const bind_car_dialog = getSession("bind_car_dialog")
    if (bind_car_dialog) {
      removeSession("bind_car_dialog")
      this.$dialog
        .confirm({
          title: "提示",
          message: "您未绑定健康卡，部分功能不能使用",
          confirmButtonText: "立刻绑卡",
          cancelButtonText: "再等等",
        })
        .then(() => {
          this.$router.push({ path: "/health-card" })
        })
        .catch(() => {
          // on cancel
        })
    }
  },
  methods: {
    get_user_head,
    onTapCar() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/other/parking`
    },
    onTapSee() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/visit/visitHome`
    },
    onTapDou() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.xdmHost}/wxcityserver-static/app.html#/login-wx?departmentName=sz_lh_yy`
    },
    onTapNav() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.yndhHost}/send-template-v3/v3.1/index.html#/search?name=luohuqurenminyiyuan`
    },
    onTapFee() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/outp/pay/card?regNo=${this.defaults_data.patient_id}&name=${this.defaults_data.name}&cardNo=${this.defaults_data.id_card}`
    },
    onTapHealthDoc() {
      this.$router.push({ path: "/health-record/2" })
    },
    onTapCheck(type) {
      this.$router.push({
        path: type === 1 ? "/colonoscopy?id=32" : "/inspection?mode=exams",
      })
    },
    onTapHealthReports() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/report/index?regNo=${this.defaults_data.patient_id}&&name=${this.defaults_data.name}&cardNo=${this.defaults_data.id_card}`
    },
    onTapTJ() {
      window.location.href = ` ${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/checkup/index`
    },
    onTapLabs() {
      this.$router.push({ path: "/inspection?mode=labs" })
    },
    onTapHospital() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/outp/outp/history?regNo=${this.defaults_data.patient_id}&name=${this.defaults_data.name}&cardNo=${this.defaults_data.id_card}`
    },
    onTapRecords() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/outp/outp/history?regNo=${this.defaults_data.patient_id}&name=${this.defaults_data.name}&cardNo=${this.defaults_data.id_card}`
    },
    onTapMoney() {
      window.location.href =
        window.GLOBAL_CONFIG?.luohu_url?.basisHost +
        "/nginx/lhyywebhospital/outp/deposit"
    },
    onTapGirls() {
      window.location.href = `${window.GLOBAL_CONFIG?.luohu_url?.basisHost}/nginx/lhyywebhospital/outp/outp/history?regNo=${this.defaults_data.patient_id}&name=${this.defaults_data.name}&cardNo=${this.defaults_data.id_card}`
    },
    onTapMessage() {
      this.$router.push({ path: "/history-message" })
    },
    login() {
      // bind_car_dialog只用于是否弹出绑卡提示！！！
      // setSession(true, "bind_car_dialog");
      this.$router.push("/login")
    },
    async main() {
      this.loading = true
      try {
        const { data } = await main_info()
        this.data = data
      } finally {
        this.loading = false
      }
    },
    async medical_cards_defaults() {
      this.loading = true
      let auth = getSession()
      try {
        const { data } = await get_medical_cards_defaults()
        if (data && data.name) {
          this.gender = data.gender
          this.type = data.type
          auth.has_card = true
          setSession(auth)
        } else {
          auth.has_card = false
          setSession(auth)
        }
        this.defaults_data = data
      } finally {
        this.loading = false
      }
    },
    onTapHealthCheck() {
      window.location.href =
        window.GLOBAL_CONFIG?.luohu_url?.basisHost +
        "/nginx/lhyywebhospital/inspect/phySelect"
    },
    onTapAppointment() {
      this.$router.push({ path: "/select-institutions" })
    },
    onTapConsultation() {
      this.$router.push({ path: "/consultation" })
    },
    onTapOnline() {
      this.$router.push({ path: "/online-inquiry" })
    },
    onTapAlert() {
      this.$router.push({ path: "/page-alert" })
    },
    jup_registered() {
      this.$router.push({ path: "/health-card" })
    },
    jup_family() {
      this.$router.push({ path: "/my-family" })
    },
  },
}
</script>
<style scoped>
.home-containter {
  color: #565656;
  width: 100%;
}

.home-containter-header {
  background-image: url("../../assets/home/img-home-header-bg@3x.png");
  height: 140px;
  background-size: 100% 100%;
}

.home-containter-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 76px;
}

.home-containter-header-top .logo {
  padding: 10px 18px;
  flex: 1;
}

.home-containter-header-top .logo h3 {
  font-size: 18px;
  margin: 4px 0px;
  font-weight: 500;
}

.home-containter-header-top .logo p {
  font-size: 12px;
  margin: 0px;
}

.home-containter-header-top .message {
  width: 70px;
  margin-right: 10px;
  margin-top: -20px;
}
.home-containter-header-top .message img {
  position: relative;
  width: 30px;
  z-index: 10;
  border-radius: 50%;
  border: 2px #fff solid;
}
.home-containter-header-top .message .message-btn {
  position: relative;
  width: 70px;
  height: 26px;
  border-radius: 13px;
  background: #fff;
  font-size: 14px;
  color: #008fff;
  margin-top: -26px;
  padding-left: 32px;
  font-weight: bold;
  line-height: 26px;
}
.home-containter-header-top .message-badge {
  background: #ff4444;
  border: 1px #fff solid;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: -26px;
  position: relative;
  margin-left: 62px;
}

.home-containter-header-card {
  height: 64px;
  background: #fff;
  margin: 0px 17px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-containter-header-card .user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0px 10px;
}
.home-containter-header-card .user-info img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.home-containter-header-card .user-info .user-info-des {
  margin-left: 10px;
}

.home-containter-header-card .user-info .user-info-des h3 {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  margin-top: 4px;
}

.home-containter-header-card .user-info .user-info-des p {
  color: #707070;
  font-size: 12px;
  margin: 0px;
  margin-top: 4px;
}

.home-containter-header-card .user-info .user-info-des span {
  color: #008fff;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.home-containter-header-card .user-info .user-info-des span img {
  height: 14px;
  width: 14px;
  margin-bottom: 3px;
  margin-left: 10px;
}

.home-containter-header-card .qrcode {
  width: 60px;
  text-align: center;
  font-size: 12px;
  color: #008fff;
}

.home-containter-header-card .qrcode img {
  width: 22px;
  height: 22px;
  margin: 0px auto;
  margin-bottom: 4px;
  display: block;
}

.home-containter-header-mask {
  height: 6px;
  background: #fff;
  -webkit-box-shadow: 0 -2px 4px 0 rgba(138, 138, 138, 0.1);
  box-shadow: 0 -2px 4px 0 rgba(138, 138, 138, 0.1);
}

.home-containter-bodyer {
  padding: 10px 17px;
}
.home-containter-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-containter-banner .items {
  width: 48.09%;
  text-align: center;
}
.home-containter-banner .items img {
  width: 100%;
}

.home-containter-ai-guide {
  width: 100%;
  background: #f1f7fd;
  height: 44px;
  margin-top: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home-containter-ai-guide img {
  width: 30px;
  margin: 0px 10px;
}
.home-containter-ai-guide span {
  color: #008fff;
  font-weight: 600;
}
.home-containter-ai-guide img.arrow {
  width: 8px;
}

.home-containter-title {
  margin: 20px 0px;
}
.home-containter-title h3 {
  font-size: 18px;
  font-weight: bold;
  color: #242938;
}
.home-containter-title p {
  font-size: 14px;
  font-weight: normal;
  color: #a1a5a6;
  margin: 4px 0px;
}

.home-containter-menus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.home-containter-menus-items {
  width: 25%;
  text-align: center;
  margin-bottom: 10px;
}
.home-containter-menus-items img {
  width: 32px;
  display: block;
  margin: 6px auto;
}
.home-ad-banner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-ad-banner-left {
  width: 48.09%;
}

.home-ad-banner-left img {
  width: 100%;
}
.home-ad-banner-right {
  width: 48.09%;
}
.home-ad-banner-right img {
  width: 100%;
}
/**提示 */
.home-containter-alert {
  border-top-left-radius: 10px;
}
.home-containter-alert-header {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #67baff;
  font-size: 16px;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  margin-top: 20px;
}

.home-containter-alert-header img {
  width: 32px;
  margin: 0px 8px;
}

.home-containter-alert-bodyer {
  padding: 10px;
  border: 1px #f2f2f2 solid;
}
.home-containter-alert-bodyer-items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px #eee solid;
  padding: 10px 6px;
}

.home-containter-alert-bodyer-items:last-child {
  border-bottom: none;
}

.home-containter-alert-bodyer-items img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.home-containter-alert-bodyer-items .infos {
  width: 200px;
}

.home-containter-alert-bodyer-items .infos h3 {
  font-size: 16px;
  font-weight: bold;
  color: #242938;
  line-height: 1.4;
}

.home-containter-alert-bodyer-items .infos p {
  font-size: 14px;
  color: #565656;
  margin: 4px 0px;
}

.home-hot-down-banner {
  width: 49%;

  & img {
    width: 100%;
  }
}
.home-containter-alert-bodyer-items .date {
  flex: 1;
  text-align: right;
}
</style>
